import { hot } from 'react-hot-loader/root'
import React from 'react'
import './App.css'
import { Home } from './components/pages/Home'

const App: React.FC = () => {
  return (
    <div className="App">
      <Home />
    </div>
  )
}

export default hot(App)
