import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import { CSSProperties } from 'react'

type LoaderProps = {
  size?: number
  fill?: string
  style?: CSSProperties
  className?: string
}

const loadAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const StyledLoader = styled.span<LoaderProps>`
  display: block;
  border: ${(props) => props.size! * 0.1 + 'px'} solid transparent;
  border-top: ${(props) => props.size! * 0.1 + 'px'} solid
    ${(props) => props.fill};
  border-radius: 50%;
  width: ${(props) => props.size! + 'px'};
  height: ${(props) => props.size! + 'px'};
  animation: ${loadAnimation} 1.1s infinite linear;
`
export const Loader: React.FC<LoaderProps> = ({
  size = 30,
  fill = '#fff',
  ...props
}: LoaderProps) => {
  return <StyledLoader size={size} fill={fill} {...props} />
}
