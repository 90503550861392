import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Loader } from '../../Loading'

const PrintOnly = styled.div`
  padding-top: 21.2mm;
  @media print {
    page-break-before: always;
    width: 100%;
  }
`

const Wrapper = styled.div`
  margin-left: 18.6mm;
  margin-right: 18.6mm;
`

const Item = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 1rem;
  width: 50%;
  height: 42.3mm;
  border: 1px solid #000;
  page-break-after: always;
  @media print {
    border: 0;
  }
`

const List = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
  //display: flex;
  flex-wrap: wrap;
  border: 1px solid #000;
  @media print {
    border: 0;
  }
`

const ClosedButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  appearance: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  font-size: 10px;
  border: 2px solid #000;
  border-top: 0;
  border-right: 0;
  @media print {
    display: none;
  }
`

const LabelBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  & + & {
    margin-top: 0.5rem;
  }
`

const Label = styled.label`
  width: 3rem;
`

const Input = styled.input`
  width: 100%;
  flex: 1;
  height: 32px;
  padding: 0.2rem 0.5rem;
  font-size: 1rem;
`

const SubmitButton = styled.button`
  margin-top: 1rem;
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  background: #00b2ea;
  color: #fff;
  border-radius: 0;
  font-size: 1rem;
  border: 0;
  padding: 0.5rem;
  font-weight: bold;
  cursor: pointer;
  &:disabled {
    cursor: wait;
    &:hover {
      opacity: 1;
    }
  }
  &:hover {
    opacity: 0.8;
  }
`

type printType = {
  id: number
  name: string
  code: string
  address: string
}

const SearchBox = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  background: aliceblue;
  height: 100%;
  width: 400px;
  overflow: auto;
  padding: 2rem;
  border-left: 2px solid #61dafb;
  z-index: 99;
`

type SearchListItemProps = {
  selected: boolean
}

const SearchListItem = styled.div<SearchListItemProps>`
  display: flex;
  background-color: #fff;
  padding: 0;
  ${(props) =>
    props.selected &&
    `
  opacity: 0.5;
  `}
`

const SearchListBox = styled.div`
  position: relative;
  height: 350px;
  overflow: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 1px solid #ccc;
  ${SearchListItem} {
    & + ${SearchListItem} {
      border-top: 1px solid #ccc;
    }
  }
`

const SearchListItemButton = styled.div`
  position: relative;
  width: 50px;
  & button {
    appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #cc0000;
    color: #fff;
    font-size: 16px;
    border: 0;
    cursor: pointer;
    font-weight: bold;
    &:hover {
      opacity: 0.8;
    }
  }
`

const SearchListItemDetail = styled.div`
  flex: 1;
  padding: 1rem;
`

const Button = styled.button`
  appearance: none;
  font-size: 16px;
  height: 32px;
`

export const Home = () => {
  // const initialState: printType[] = [...Array(30)].map(() => {
  //   return {
  //     id: 1,
  //     name: '丹野',
  //     code: '980-0003',
  //     address: '仙台市青葉区小田原7-5-5',
  //   }
  // })
  const initialState: printType[] = []
  const [list, setList] = useState(initialState)
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [tel, setTel] = useState('')
  const [empty, setEmpty] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [searchList, setSearchList] = useState([] as printType[])

  const deleteItem = useCallback(
    (item) => {
      const _list = [...list]
      const index = list.findIndex((_item) => {
        return _item.id === item.id
      })
      _list.splice(index, 1)
      setList(_list)
    },
    [list]
  )

  const submit = useCallback(() => {
    setIsLoading(true)
    fetch(
      `https://neo.masterminds.jp/api/v1/appointments/search?limit=30&name=${name}&address=${address}&tel1=${tel}`,
      {
        credentials: 'include',
      }
    )
      .then((response) => {
        if (response) {
          return response.json()
        }
      })
      .then((myJson: any) => {
        console.log(myJson.appointment)
        const data = myJson.appointment.map((appointment: any) => {
          return {
            id: appointment.id,
            address: appointment.address1 + ' ' + (appointment.address2 || ''),
            name: appointment.f_name + ' ' + appointment.l_name,
            code:
              '〒' +
              appointment.zip.substr(0, 3) +
              '-' +
              appointment.zip.substr(-4),
          }
        })
        setIsLoading(false)
        setSearchList(data)
      })
      .catch((e) => {
        console.log(e)
        alert('ログインしてください')
        setIsLoading(false)
      })
  }, [name, address, tel])

  return (
    <Wrapper>
      <SearchBox className={'search-box'}>
        <h2 style={{ textAlign: 'center', marginTop: 0 }}>検索</h2>
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault()
            submit()
          }}
        >
          <LabelBox>
            <Label htmlFor="">名前</Label>
            <Input
              type="text"
              placeholder={'名前'}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </LabelBox>
          <LabelBox>
            <Label htmlFor="">住所</Label>
            <Input
              type="text"
              placeholder={'address'}
              value={address}
              onChange={(e) => {
                setAddress(e.target.value)
              }}
            />
          </LabelBox>
          <LabelBox>
            <Label htmlFor="">TEL</Label>
            <Input
              type="text"
              placeholder={'電話番号'}
              value={tel}
              onChange={(e) => {
                setTel(e.target.value)
              }}
            />
          </LabelBox>
          <SubmitButton type={'submit'} disabled={isLoading && true}>
            検索
          </SubmitButton>

          <p>
            <small>
              *<strong>印刷時は余白なしで印刷してください</strong>
              <br />*
              <strong>
                <a
                  href={
                    'https://bungu.plus.co.jp/labellab/compose/address/#face012'
                  }
                  target={'_blank'}
                >
                  用紙はこちら（12面 四辺余白付 ME-507）
                </a>
              </strong>
              <br />
              <a href={'https://neo.masterminds.jp/'} target={'_blank'}>
                *NEOにログインして使用してください。（neo.masterminds.jp）
              </a>
              <br />
              *下のリストに表示されるものは30件です。
            </small>
          </p>
        </form>

        <SearchListBox>
          {searchList.length === 0 && !isLoading && (
            <div style={{ padding: '2rem', textAlign: 'center' }}>
              データはありません
            </div>
          )}
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Loader fill={'#00b2ea'} />
            </div>
          )}
          {searchList.map((item, i) => {
            const isSelected =
              list.filter((_item) => {
                return _item.id === item.id
              }).length > 0
            return (
              <SearchListItem selected={isSelected} key={i}>
                <SearchListItemDetail>
                  <div style={{ marginBottom: '0.5em' }}>{item.name}</div>
                  <div>{item.address}</div>
                  <a
                    href={
                      'https://neo.masterminds.jp/#/appointment/view/' + item.id
                    }
                    target={'_blank'}
                  >
                    NEOへ飛ぶ
                  </a>
                </SearchListItemDetail>
                <SearchListItemButton>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      const newList = [...list]
                      newList.push(item)
                      setList(newList)
                    }}
                    disabled={isSelected && true}
                    style={{ cursor: isSelected ? 'not-allowed' : 'pointer' }}
                  >
                    追加
                  </button>
                </SearchListItemButton>
              </SearchListItem>
            )
          })}
        </SearchListBox>
        <p style={{ fontSize: '14px' }}>
          空のシートを作る場合下から入力してください。
        </p>
        <form action="">
          <LabelBox>
            <Label htmlFor="" style={{ fontSize: '13px' }}>
              空の数
            </Label>
            <Input
              type="text"
              placeholder={'空の数'}
              value={empty}
              style={{ marginRight: '5px' }}
              onChange={(e) => {
                setEmpty(e.target.value.replace(/[^0-9]/g, ''))
              }}
            />
            <Button
              type={'submit'}
              onClick={(e) => {
                e.preventDefault()
                if (Number(empty) === 0 || empty === '') {
                  alert('0以上を入力してください')
                  return
                }
                const _list = [...list]
                for (let i = 0; i < Number(empty); i++) {
                  _list.unshift({
                    id: performance.now(),
                    address: '',
                    code: '',
                    name: '',
                  })
                }
                setList(_list)
              }}
            >
              追加
            </Button>
          </LabelBox>
        </form>
      </SearchBox>
      <List className={'list'}>
        {list.map((item, i) => {
          return (
            <React.Fragment key={i}>
              {i !== 0 && i % 12 === 0 && <PrintOnly />}
              <Item className={'list'}>
                <ClosedButton
                  onClick={(e) => {
                    e.preventDefault()
                    deleteItem(item)
                  }}
                >
                  <span role={'img'} aria-label={'削除'}>
                    ❌
                  </span>
                </ClosedButton>
                <p style={{ marginTop: 0, fontSize: '14px' }}>{item.code}</p>
                <p style={{ marginTop: 0, fontSize: '14px' }}>{item.address}</p>
                <p style={{ marginTop: 0, marginBottom: 0, fontSize: '14px' }}>
                  {item.name && <>{item.name} 様</>}
                </p>
              </Item>
            </React.Fragment>
          )
        })}
      </List>
    </Wrapper>
  )
}
